<script>
import ButtonGroups from '../Common/ButtonGroups.vue';
import LoadingBox from '../Common/LoadingBox.vue';
import MessageBox from '../Common/MessageBox.vue';

export default {
  components: { LoadingBox, MessageBox, ButtonGroups },
  name: "UserActivityLog",

  data() {return {
    logs: [],
    page: 0,
    waitResponse: true,
  }},

  computed: {
    logsLength() {
      return this.logs.length;
    }
  },

  created() {
    this.RequestLogs();
  },

  methods: {
    RequestLogs()
    {
      this.logs = [];
      this.waitResponse = true;

      const params = {
        page: this.page
      };
      this.$api.get({ path: "/app/user-activity", params }, this.OnLogsResponse)
    },

    OnLogsResponse({response})
    {
      const { data } = response;
      this.logs = data.logs;
      this.waitResponse = false;
    },

    SetPage(pageNum)
    {
      this.page += pageNum;
      this.RequestLogs();
    }
  },

}
</script>

<template>
<div class="user-activity-log">

  <div class="page-header">
    <h2>Activitate</h2>
    <button @click="RequestLogs" class="no-bg border">
      Refresh
    </button>
  </div>

  <div class="box activity-table">
    <div class="activity-row activity-header">
      <div class="col">
        Acțiune
      </div>

      <div class="col">
        Utilizator
      </div>

      <div class="col">
        Utilizator Afectat
      </div>

      <div class="col">
        Data
      </div>

    </div>

    <loading-box v-if="waitResponse"></loading-box>
    <message-box v-if="logsLength == 0 && !waitResponse">
      <h3>No results</h3>
    </message-box>

    <div class="activity-row" v-for="log in logs" :key="log" v-tooltip="log.details || 'Nu există detalii'">

      <div class="col">
        {{ log.action }}
      </div>

      <div class="col">
        {{ log.user_profile.f_name }}
        {{ log.user_profile.s_name }}
      </div>

      <div class="col">
        {{ log.target_profile?.f_name }}
        {{ log.target_profile?.s_name || "-" }}
      </div>

      <div class="col">
        {{ log.timestamp }}
      </div>
    </div>

    <div class="activity-bottom">
      <button-groups>
        <button @click="SetPage(-1)" :disabled="page == 0" class="no-bg border activity-nav-btn">
          <icon>chevron_left</icon>
        </button>
        <button @click="SetPage(-page)" :disabled="page == 0" class="no-bg border activity-nav-btn">
          <icon>first_page</icon>
        </button>
        <button @click="SetPage(1)" :disabled="logsLength < 10" class="no-bg border activity-nav-btn">
          <icon>chevron_right</icon>
        </button>
      </button-groups>
      <span class="label-text">Pagina: {{ page + 1 }}</span>
    </div>
  </div>
</div>
</template>



<style scoped>
.page-header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.user-activity-log {
  width: 100%;
  padding: 10px;
  /* height: 400px; */
}

.activity-table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 20px;
}


.activity-row {
  display: grid;
  font-size: var(--normal-text);
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
  align-items: center;
  border-bottom: var(--solid-border);
}

.activity-row:hover {
  background-color: var(--dark-box-color);
}

.activity-row:last-child {
  border-bottom: none;
}

.activity-row .col {
  padding: 10px;
}

.activity-header {
  background-color: var(--dark-box-color);
  font-weight: bold;
}

.activity-header .col {
  padding: 15px;
}

.activity-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 15px;
}

.activity-nav-btn .icon {
  font-size: medium;
}

.loading-box {
  height: 380px;
}
</style>