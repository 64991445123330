<script>
import ApplicationConfig from '../../script/ApplicationConfig';

import ToggleButton from '../Common/ToggleButton.vue'

export default {
  components: { ToggleButton },
  name: "SystemGeneral",

  data() { return {
    systemInfo: {}
  }},

  created()
  {
    this.RequestSystemInfo();
  },

  methods: {
    RequestSystemInfo()
    {
      this.$api.get({ path: "/app/system-info" }, this.OnSystemResponse)
    },

    OnSystemResponse({response})
    {
      this.systemInfo = response.data;
    },

    ToggleConfig(fieldName)
    {
      ApplicationConfig.ToggleConfig(fieldName);
    }
  }
}
</script>
  
<template>
<div class="box system-config">
  <Title>
    System config
  </Title>

  <div class="info-box">
    <span v-for="value, name in systemInfo" :key="name">
      <b>{{name}}</b>: {{value}}
    </span>
  </div>

  <div class="config-list">
    <toggle-button :toggled="value" @toggle="ToggleConfig(name)" v-for="(value, name) in $global.config" :key="name">
      {{$lan("config_" + name)}}
    </toggle-button>
  </div>
</div>
</template>
  
  
<style scoped>
.system-config {
  /* min-width: 350px; */
  width: 100%;
  overflow: hidden;
}

.config-list {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
  padding: 10px;
  gap: 10px;
}

.toggle-container {
  padding: 10px;
  border-radius: 10px;
}

.toggle-container:hover {
  background-color: var(--box-color-blue);
}

.info-box {
  display: flex;
  flex-direction: column;
  background-color: var(--dark-box-color);
  margin: 10px;
  padding: 15px;
  border-radius: 20px;
  gap: 10px;
}

.info-box span {
  word-wrap: break-word;
  word-break: break-all;
}
</style>