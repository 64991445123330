<script>
import General from '../../components/System/General.vue';
import UserActivityLog from '../../components/System/UserActivityLog.vue';

export default {
  name: "SystemPage",

  components: {General, UserActivityLog}
}
</script>

<template>
  <div class="system-page">
    <General class="pop-in"></General>
    <UserActivityLog class="pop-in"></UserActivityLog>
  </div>
</template>


<style scoped>
.system-page {
  justify-content: center;
  gap: 10px;
}

.pop-in {
  animation: zoom-in 0.1s;
}
</style>